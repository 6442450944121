<template>
    <div class="coupon_container">
        <div
            :class="{
                invalid: couponData.status === 'invalid',
                invalid_tag: ( couponData.status === 'invalid' && !couponData.hiddenTag ),
                stop: couponData.status === 'stop',
                bottom_ra: couponData.displayReason === true
            }"
            @click="handleItemSelect"
            class="coupon_item_wrapper">
            <span
                v-ellipsis="1"
                class="coupon_title">{{ couponData.name }}</span>
            <span
                v-if="couponData.selected"
                :class="couponData.check ? 'checked' : ''"
                class="coupon_select" />
            <span class="coupon_symbol">¥</span>
            <span
                v-html="transformPrice"
                class="coupon_price" />
            <span class="expired">有效期: {{ couponData.endTime | filterEndTime }}</span>
        </div>
        <div
            v-if="couponData.displayReason"
            class="reason">
            不可用原因：{{ couponData.unavailableReason }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CouponItem',
    filters: {
        filterEndTime(endTime) {
            let time;
            if (endTime) {
                time = typeof endTime === 'string' && endTime.split(' ')[0];
            } else {
                time = '永久有效';
            }
            return time;
        },
    },
    props: {
        couponData: {
            require: true,
            type: Object,
            default: () => {},
        },
    },
    computed: {
        transformPrice() {
            let price;
            if (typeof this.couponData.balance === 'number') {
                price = this.couponData.balance.toFixed(2);
            } else {
                price = this.couponData.balance;
            }
            const arr = price.split('.');
            return `<span class="yuan">${arr[0]}.</span><span class="jiao">${arr[1]}</span>`;
        },
    },
    methods: {
        handleItemSelect() {
            this.$emit('toggleCouponItemSelect', this.couponData);
        },
    },
};
</script>

<style lang="scss">
.coupon_item_wrapper {
    .coupon_price {
        position: absolute;
        left: 28px;
        bottom: 14px;
        font-family: DINAlternate-Bold, DINAlternate, sans-serif;
        .yuan {
            font-size: 26px;
            color: #fff;
        }
        .jiao {
            font-size: 18px;
            color: #fff;
        }
    }
    &.invalid {
        .coupon_price {
            .yuan {
                font-size: 26px;
                color: rgba(78, 78, 78, 0.45);
            }
            .jiao {
                font-size: 18px;
                color: rgba(78, 78, 78, 0.45);
            }
        }
    }
    &.stop {
        .coupon_price {
            .yuan {
                color: rgba(13, 40, 154, 0.55);
            }
            .jiao {
                color: rgba(13, 40, 154, 0.55);
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import './mixin.scss';
.coupon_container {
    margin-bottom: 12px;
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
    border-radius: 8px;
    .coupon_item_wrapper {
        position: relative;
        width: 100%;
        height: 88px;
        background: url('./bg.png');
        background-size: 100% 88px;
        border-radius: 8px;
        &.bottom_ra {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.invalid {
            background: linear-gradient(139deg, #ecedf0 0%, #e6e6e7 100%);
            .coupon_title,
            .coupon_symbol,
            .expired {
                color: rgba(78, 78, 78, 0.45);
            }
        }
        &.invalid_tag {
            &::after {
                @include statusTag('已失效');
            }
        }
        &.stop {
            background: linear-gradient(139deg, #dce2f4 0%, #c0cbf3 100%);
            .coupon_title,
            .coupon_symbol,
            .expired {
                color: rgba(13, 40, 154, 0.55);
            }
            &::after {
                @include statusTag('已停用');
            }
        }
        .coupon_title {
            position: absolute;
            top: 11px;
            left: 17px;
            right: 34px;
            display: inline-block;
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            color: #fff;
            font-weight: 500;
        }
        .coupon_select {
            position: absolute;
            top: 13px;
            right: 14px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background: rgba(245, 247, 250, 0.2);
            border-radius: 50%;
            border: 1px solid #fff;
            box-sizing: border-box;
            &.checked {
                background-image: url('./select.png');
                background-size: 20px 20px;
                border: none;
            }
        }
        .coupon_symbol {
            position: absolute;
            left: 17px;
            bottom: 12px;
            display: inline-block;
            height: 24px;
            line-height: 24px;
            font-size: 14px;
            color: #fff;
        }
        .coupon_price {
            position: absolute;
            left: 28px;
            bottom: 14px;
        }
        .expired {
            position: absolute;
            right: 17px;
            bottom: 13px;
            display: inline-block;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
        }
    }
    .reason {
        padding-left: 18px;
        height: 32px;
        width: 100%;
        box-sizing: border-box;
        color: rgba(58, 58, 58, 0.7);
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        display: flex;
        justify-content: left;
        align-items: center;
    }
}
</style>
